import React, { Component } from 'react';
import './Works.css';
import { NavMenu } from './NavMenu';
import { FloorPlans } from './FloorPlans';
import { Schemes } from './Schemes';
import { Home } from './Home';
import { Videos } from './Videos';
import { Objects } from './Objects';
import { FinishedWorks } from './FinishedWorks'
import { HashLink } from 'react-router-hash-link';


//removed components
/* <div id="FinishedWorksContainer">
   <FinishedWorks id="FinishedWorks" />
</div> */

export class Works extends Component {
    displayName = Schemes.name

    render() {
        return (
            <div>
                <React.Fragment>
                    <div id="WorksContainer">
                        <div id="FloorPlansContainer">
                            <FloorPlans id="FloorPlans" />
                        </div>
                        <div id="SchemesContainer">
                            <Schemes id="Schemes" />
                        </div>
                        <div id="ObjectsContainer">
                            <Objects id="Objects" />
                        </div>
                        <div id="worksFooter">  <HashLink smooth to="#greaterNavContainer">Back to Top </HashLink></div>
                    </div>
                </React.Fragment>
            </div>
        );
    }
}

