import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import './Home.css';

export class Home extends Component {
    displayName = Home.name

    render() {
        return (
            <div id="HomeMainContainer">
            </div>
        );
    }
}
