import React, { Component } from 'react';
import './Schemes.css';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

/*local data imports
import one30East18thStreet from "./images/one30East18thStreet.png";
import one40East81stStreet from "./images/one40East81stStreet.png";
import one53East87thStreet from "./images/one53East87thStreet.png";
import three23West11thStreet from "./images/three23West11thStreet.png";
*/

export class Schemes extends Component {
    constructor(props) {
        super(props);
        this.schemesGalleryRef = React.createRef();
        this.state = {
            navTextStatus0: "schemesLocationTextActive",
            navTextStatus1: "schemesLocationText",
            navTextStatus2: "schemesLocationText",
            navTextStatus3: "schemesLocationText",
            navTextStatus4: "schemesLocationText",
            navTextStatus5: "schemesLocationText",
        }
    }
    displayName = Schemes.name

    updateNavStatus = (passedIndex) => {

        this.state.navTextStatus0 = "schemesLocationText";
        this.state.navTextStatus1 = "schemesLocationText";
        this.state.navTextStatus2 = "schemesLocationText";
        this.state.navTextStatus3 = "schemesLocationText";
        this.state.navTextStatus4 = "schemesLocationText";
        this.state.navTextStatus5 = "schemesLocationText";

        this.schemesGalleryRef.current.slideToIndex(passedIndex);
        switch (passedIndex) {
            case 0:
                this.setState({ navTextStatus0: "schemesLocationTextActive" })
                break;
            case 1:
                this.setState({ navTextStatus1: "schemesLocationTextActive" })
                break;
            case 2:
                this.setState({ navTextStatus2: "schemesLocationTextActive" })
                break;
            case 3:
                this.setState({ navTextStatus3: "schemesLocationTextActive" })
                break;
            case 4:
                this.setState({ navTextStatus4: "schemesLocationTextActive" })
                break;
            case 5:
                this.setState({ navTextStatus5: "schemesLocationTextActive" })
                break;

        }
    }

    render() {

        const schemesImages = [
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/schemes/130+East+18th+Street.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/schemes/130+East+18th+Street.png',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/schemes/140+East+81st+Street.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/schemes/140+East+81st+Street.png',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/schemes/153+East+87th+Street.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/schemes/153+East+87th+Street.png',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/schemes/200+Riverside+Blvd.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/schemes/200+Riverside+Blvd.png',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/schemes/323+West+11th+Street.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/schemes/323+West+11th+Street.png',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/schemes/40+West+72nd+Street.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/schemes/40+West+72nd+Street.png',
            }

        ]

        return (
            <div>
                <React.Fragment>
                    <div id="schemesContainer">
                        <h1 id="schemesText"> Schemes </h1>
                        <div id="schemesLocationContainer">
                            <div id={this.state.navTextStatus0} onClick={() => this.updateNavStatus(0)} > 130 East 18th Street. &nbsp; </div>
                            <div id={this.state.navTextStatus1} onClick={() => this.updateNavStatus(1)} > 140 East 81st Street.&nbsp; </div>
                            <div id={this.state.navTextStatus2} onClick={() => this.updateNavStatus(2)} > 153 East 87th Street. &nbsp; </div>
                            <div id={this.state.navTextStatus3} onClick={() => this.updateNavStatus(3)} > 200 Riverside Boulevard. &nbsp; </div>
                            <div id={this.state.navTextStatus4} onClick={() => this.updateNavStatus(4)} > 323 West 11th Street &nbsp; </div>
                            <div id={this.state.navTextStatus5} onClick={() => this.updateNavStatus(5)} > 40 West 72nd Street. &nbsp; </div>

                        </div>
                        <div id="schemesCarouselPaddingContainer">
                            <div id="schemesCarouselContainer">
                                <ImageGallery items={schemesImages} ref={this.schemesGalleryRef} showThumbnails={false} showPlayButton={false} showFullscreenButton={true} showNav={false} />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            </div>
        );
    }
}

