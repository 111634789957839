import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import './AboutDetails.css';

export class AboutDetails extends Component {
    displayName = AboutDetails.name
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <div className={this.props.passedClassName}>
                <div className="aboutText"> Nicole Winkler is a maker, drafter and designer, born and raised in New York City.
                Her experience ranges from documenting existing buildings to gutting and redesigning homes to producing custom ceramic dining sets.
                    Nicole completed her graduate degree, Master of Architeture from the Savannah College of Art and Design in 2017 and has been working independently in New York City since.</div>

            </div>
        );
    }
}
