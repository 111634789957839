import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Glyphicon, Nav, Navbar, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './NavMenu.css';
import { HashLink } from 'react-router-hash-link';

class NavMenuLink extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <li>
                        <NavLink to={"/" + this.props.pagename1}
                            className="inactiveNavLink"
                            onClick={() => this.onClickWorksEvent()}
                            activeClassName="activeNavLink">
                            {this.props.displaypagename1}

                        </NavLink>
                    </li>
                </div>
            </React.Fragment>
        )
    }
}


export class NavMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hashLinkContainerStatus: "HashLinkContainerInactive",
            isNavBarHidden: (props.isNavBarHidden),

        };
    }
    displayName = NavMenu.name

    onClickWorksEvent() {

        this.props.worksMouseClickEvent();
        this.setState({ hashLinkContainerStatus: "HashLinkContainerActive" })
        /* this.setState({ isNavBarHidden: "navBarActive" }); */
    }

    render() {

        return (
            <React.Fragment>
                <div id="greaterNavContainer">
                    <div id="spacer1"></div>
                    <div id="navContainer">
                        <div>
                            <div id="WorksLabel"
                                onClick={() => this.onClickWorksEvent()}
                            >Works</div>
                        </div>
                        <div id={this.state.hashLinkContainerStatus}>
                                <HashLink smooth to="/#FloorPlansContainer">Floor Plans</HashLink> <br></br>
                                <HashLink smooth to="/#SchemesContainer">Schemes</HashLink><br></br>
                                <HashLink smooth to="/#ObjectsContainer">Objects</HashLink><br></br>
                        </div>
                        <nav id="mainNav">
                            <div className={this.state.isNavBarHidden}>
                                    <ul>
                                        <NavMenuLink
                                            pagename1="Works"
                                            displaypagename1="Works"
                                            currentPath={this.props.location.pathname} />
                                    </ul>
                                </div>
                            </nav>
                    </div>
                    <div id="spacer2"></div>
                </div>
            </React.Fragment>

        );
    }

    
}
