import React, { Component } from 'react';
import './FloorPlans.css';
import { NavMenu } from './NavMenu';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
/* LOCAL PHOTO IMPORTS
import oneFifthAvenue from "./images/oneFifthAvenue.png";
import elevenBarrowStreet from "./images/elevenBarrowStreet.png";
import elevenWest17thSreet from "./images/elevenWest17thSreet.png";
import twelveJohnStreet from "./images/twelveJohnStreet.png";
import twelveWest68Street from "./images/twelveWest68Street.png";
import thirteenBarrowStreet from "./images/thirteenBarrowStreet.png";
import twenty1West19thStreet from "./images/twenty1West19thStreet.png";
import thirty5West19thStreet from "./images/thirty5West19thStreet.png";
import eighty8ReadeStreet from "./images/eighty8ReadeStreet.png";
import one34East26thStreet from "./images/one34East26thStreet.png";
import two05East76thstreet from "./images/two05East76thstreet.png";
import two20West71stStreet from "./images/two20West71stStreet.png";
import two30ElizabethStreet from "./images/two30ElizabethStreet.png";
import two43West98thStreet from "./images/two43West98thStreet.png";
import three36CanalStreet from "./images/three36CanalStreet.png";
import three75BleeckerStreet from "./images/three75BleeckerStreet.png";
import three87EastFordhamRoad from "./images/three87EastFordhamRoad.png";
import four132ndAve from "./images/four132ndAve.png";
import four44CyrusPlace from "./images/four44CyrusPlace.png";
import eight00GrandConcourse from "./images/eight00GrandConcourse.png";
*/


export class FloorPlans extends Component {
    constructor(props) {
        super(props);
        this.FloorPlansGalleryRef = React.createRef();
        this.state = {
            navTextStatus0: "floorPlansLocationTextActive",
            navTextStatus1: "floorPlansLocationText",
            navTextStatus2: "floorPlansLocationText",
            navTextStatus3: "floorPlansLocationText",
            navTextStatus4: "floorPlansLocationText",
            navTextStatus5: "floorPlansLocationText",
            navTextStatus6: "floorPlansLocationText",
            navTextStatus7: "floorPlansLocationText",
            navTextStatus8: "floorPlansLocationText",
            navTextStatus9: "floorPlansLocationText",
            navTextStatus10: "floorPlansLocationText",
            navTextStatus11: "floorPlansLocationText",
            navTextStatus12: "floorPlansLocationText",
            navTextStatus13: "floorPlansLocationText",
            navTextStatus14: "floorPlansLocationText",
            navTextStatus15: "floorPlansLocationText",
            navTextStatus16: "floorPlansLocationText",
            navTextStatus17: "floorPlansLocationText",
            navTextStatus18: "floorPlansLocationText",
            navTextStatus19: "floorPlansLocationText",
        }
    }
    displayName = FloorPlans.name

    updateNavStatus = (passedIndex) => {

        this.state.navTextStatus0 = "floorPlansLocationText";
        this.state.navTextStatus1 = "floorPlansLocationText";
        this.state.navTextStatus2 = "floorPlansLocationText";
        this.state.navTextStatus3 = "floorPlansLocationText";
        this.state.navTextStatus4 = "floorPlansLocationText";
        this.state.navTextStatus5 = "floorPlansLocationText";
        this.state.navTextStatus6 = "floorPlansLocationText";
        this.state.navTextStatus7 = "floorPlansLocationText";
        this.state.navTextStatus8 = "floorPlansLocationText";
        this.state.navTextStatus9 = "floorPlansLocationText";
        this.state.navTextStatus10 = "floorPlansLocationText";
        this.state.navTextStatus11 = "floorPlansLocationText";
        this.state.navTextStatus12 = "floorPlansLocationText";
        this.state.navTextStatus13 = "floorPlansLocationText";
        this.state.navTextStatus14 = "floorPlansLocationText";
        this.state.navTextStatus15 = "floorPlansLocationText";
        this.state.navTextStatus16 = "floorPlansLocationText";
        this.state.navTextStatus17 = "floorPlansLocationText";
        this.state.navTextStatus18 = "floorPlansLocationText";
        this.state.navTextStatus19 = "floorPlansLocationText";
        this.FloorPlansGalleryRef.current.slideToIndex(passedIndex);
        switch (passedIndex) {
            case 0:
                this.setState({ navTextStatus0: "floorPlansLocationTextActive" })
                break;
            case 1:
                this.setState({ navTextStatus1: "floorPlansLocationTextActive" })
                break;
            case 2:
                this.setState({ navTextStatus2: "floorPlansLocationTextActive" })
                break;
            case 3:
                this.setState({ navTextStatus3: "floorPlansLocationTextActive" })
                break;
            case 4:
                this.setState({ navTextStatus4: "floorPlansLocationTextActive" })
                break;
            case 5:
                this.setState({ navTextStatus5: "floorPlansLocationTextActive" })
                break;
            case 6:
                this.setState({ navTextStatus6: "floorPlansLocationTextActive" })
                break;
            case 7:
                this.setState({ navTextStatus7: "floorPlansLocationTextActive" })
                break;
            case 8:
                this.setState({ navTextStatus8: "floorPlansLocationTextActive" })
                break;
            case 9:
                this.setState({ navTextStatus9: "floorPlansLocationTextActive" })
                break;
            case 10:
                this.setState({ navTextStatus10: "floorPlansLocationTextActive" })
                break;
            case 11:
                this.setState({ navTextStatus11: "floorPlansLocationTextActive" })
                break;
            case 12:
                this.setState({ navTextStatus12: "floorPlansLocationTextActive" })
                break;
            case 13:
                this.setState({ navTextStatus13: "floorPlansLocationTextActive" })
                break;
            case 14:
                this.setState({ navTextStatus14: "floorPlansLocationTextActive" })
                break;
            case 15:
                this.setState({ navTextStatus15: "floorPlansLocationTextActive" })
                break;
            case 16:
                this.setState({ navTextStatus16: "floorPlansLocationTextActive" })
                break;
            case 17:
                this.setState({ navTextStatus17: "floorPlansLocationTextActive" })
                break;
            case 18:
                this.setState({ navTextStatus18: "floorPlansLocationTextActive" })
                break;
            case 19:
                this.setState({ navTextStatus19: "floorPlansLocationTextActive" })
                break;
        }
}

    render() {

        /*const floorPlanImages = [9, 8, 7, 6, 5].map((number) => ({
            src: `https://placedog.net/${number}00/${number}00?id=${number}`
        })); */

        const floorPlanImages = [

            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/oneFifthAvenue.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/oneFifthAvenue.png',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/elevenBarrowStreet.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/elevenBarrowStreet.png',
            }
            ,
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/elevenWest17thSreet.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/elevenWest17thSreet.png',
            }
            ,
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/twelveJohnStreet.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/twelveJohnStreet.png',
            }
            ,
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/twelveWest68Street.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/twelveWest68Street.png',
            }
            ,
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/thirteenBarrowStreet.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/thirteenBarrowStreet.png',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/twenty1West19thStreet.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/twenty1West19thStreet.png',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/thirty5West19thStreet.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/thirty5West19thStreet.png',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/eighty8ReadeStreet.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/eighty8ReadeStreet.png',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/one34East26thStreet.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/one34East26thStreet.png',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/two05East76thstreet.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/two05East76thstreet.png',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/two20West71stStreet.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/two20West71stStreet.png',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/two30ElizabethStreet.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/two30ElizabethStreet.png',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/two43West98thStreet.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/two43West98thStreet.png',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/three36CanalStreet.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/three36CanalStreet.png',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/three75BleeckerStreet.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/three75BleeckerStreet.png',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/three87EastFordhamRoad.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/three87EastFordhamRoad.png',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/four132ndAve.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/four132ndAve.png',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/four44CyrusPlace.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/four44CyrusPlace.png',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/eight00GrandConcourse.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/floorplans/eight00GrandConcourse.png',
            }

            /* LOCAL IMPORTS */
            /*
            {
                original: oneFifthAvenue,
                thumbnail: oneFifthAvenue,
            },
            {
                original: elevenBarrowStreet,
                thumbnail: elevenBarrowStreet,
            }
            ,
            {
                original: elevenWest17thSreet,
                thumbnail: elevenWest17thSreet,
            }
            ,
            {
                original: twelveJohnStreet,
                thumbnail: twelveJohnStreet,
            }
            ,
            {
                original: twelveWest68Street,
                thumbnail: twelveWest68Street,
            }
            ,
            {
                original: thirteenBarrowStreet,
                thumbnail: thirteenBarrowStreet,
            },
            {
                original: twenty1West19thStreet,
                thumbnail: twenty1West19thStreet,
            },
            {
                original: thirty5West19thStreet,
                thumbnail: thirty5West19thStreet,
            },
            {
                original: eighty8ReadeStreet,
                thumbnail: eighty8ReadeStreet,
            },
            {
                original: one34East26thStreet,
                thumbnail: one34East26thStreet,
            },
            {
                original: two05East76thstreet,
                thumbnail: two05East76thstreet,
            },
            {
                original: two20West71stStreet,
                thumbnail: two20West71stStreet,
            },
            {
                original: two30ElizabethStreet,
                thumbnail: two30ElizabethStreet,
            },
            {
                original: two43West98thStreet,
                thumbnail: two43West98thStreet,
            },
            {
                original: three36CanalStreet,
                thumbnail: three36CanalStreet,
            },
            {
                original: three75BleeckerStreet,
                thumbnail: three75BleeckerStreet,
            },
            {
                original: three87EastFordhamRoad,
                thumbnail: three87EastFordhamRoad,
            },
            {
                original: four132ndAve,
                thumbnail: four132ndAve,
            },
            {
                original: four44CyrusPlace,
                thumbnail: four44CyrusPlace,
            },
            {
                original: eight00GrandConcourse,
                thumbnail: eight00GrandConcourse,
            }*/
        ]


        


        return (
            <div>
                <React.Fragment>
                    <div id="FloorPlansContainer">
                        <h1 id="floorPlansText"> Floor Plans </h1>
                        <div id="floorPlansLocationContainer">
                            <div id={this.state.navTextStatus0} onClick={() => this.updateNavStatus(0)} > 1 Fifth Ave. &nbsp; </div>
                            <div id={this.state.navTextStatus1} onClick={() => this.updateNavStatus(1)} > 11 Barrow Street.&nbsp; </div>
                            <div id={this.state.navTextStatus2} onClick={() => this.updateNavStatus(2)} > 11 West 17th Street. &nbsp; </div>
                            <div id={this.state.navTextStatus3} onClick={() => this.updateNavStatus(3)} > 12 John Street. &nbsp;</div>
                            <div id={this.state.navTextStatus4} onClick={() => this.updateNavStatus(4)} > 12 West 68th Street. &nbsp;</div>
                            <div id={this.state.navTextStatus5} onClick={() => this.updateNavStatus(5)} > 13 Barrow Street. &nbsp;</div>
                            <div id={this.state.navTextStatus6} onClick={() => this.updateNavStatus(6)} > 21 West 19th Street. &nbsp;</div>
                            <div id={this.state.navTextStatus7} onClick={() => this.updateNavStatus(7)} > 35 West 19th Street. &nbsp;</div>
                            <div id={this.state.navTextStatus8} onClick={() => this.updateNavStatus(8)} > 88 Reade Street. &nbsp;</div>
                            <div id={this.state.navTextStatus9} onClick={() => this.updateNavStatus(9)} > 134 East 26th Street. &nbsp;</div>
                            <div id={this.state.navTextStatus10} onClick={() => this.updateNavStatus(10)} > 205 East 76th Street. &nbsp;</div>
                            <div id={this.state.navTextStatus11} onClick={() => this.updateNavStatus(11)} > 220 West 71st Street. &nbsp;</div>
                            <div id={this.state.navTextStatus12} onClick={() => this.updateNavStatus(12)} > 230 Elizabeth Street. &nbsp;</div>
                            <div id={this.state.navTextStatus13} onClick={() => this.updateNavStatus(13)} > 243 West 98th Street. &nbsp;</div>
                            <div id={this.state.navTextStatus14} onClick={() => this.updateNavStatus(14)} > 336 Canal Street. &nbsp;</div>
                            <div id={this.state.navTextStatus15} onClick={() => this.updateNavStatus(15)} > 375 Bleecker Street. &nbsp;</div>
                            <div id={this.state.navTextStatus16} onClick={() => this.updateNavStatus(16)} > 387 East Fordham Road. &nbsp;</div>
                            <div id={this.state.navTextStatus17} onClick={() => this.updateNavStatus(17)} > 413 2nd Ave. &nbsp;</div>
                            <div id={this.state.navTextStatus18} onClick={() => this.updateNavStatus(18)} > 444 Cyrus Place. &nbsp;</div>
                            <div id={this.state.navTextStatus19} onClick={() => this.updateNavStatus(19)} > 800 Grand Concourse. &nbsp;</div>

                            </div>
                        <div id="floorPlansCarouselPaddingContainer">
                            <div id="floorPlansCarouselContainer">
                                <ImageGallery items={floorPlanImages} ref={this.FloorPlansGalleryRef} showThumbnails={false} showPlayButton={false} showFullscreenButton={false} showNav={false} />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            </div> 
        );
    }
}

