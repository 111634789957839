import React, { Component }  from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { NavMenu } from './NavMenu';
import { Transition, CSSTransition, TransitionGroup } from 'react-transition-group';
import { Switch, Route, withRouter } from 'react-router-dom';
import { FloorPlans } from './FloorPlans';
import { Schemes } from './Schemes';
import { Home }  from './Home';
import { Videos } from './Videos';
import { Objects } from './Objects';
import { AboutDetails } from './AboutDetails';
import { ContactDetails } from './ContactDetails';
import { Works } from './Works';
import './Layout.css';


export class Layout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sidespacerContainerState: "sidespacerContainer",
            aboutContainerState: "leftBarFull",
            aboutTextState: "leftBarTextFull",
            aboutDetailsShown: false,
            aboutDetailsClassName: "aboutDetailsContainerActive",
            contactContainerState: "rightBarFull",
            contactTextState: "rightBarTextFull",
            contactDetailsShown: false,
            contactDetailsClassName: "contactDetailsContainerActive",
            isWorksActive: false,
            isNavBarHidden: "navBarHidden",
            aboutBubbleClass: "aboutBubbleHidden",
            contactBubbleClass: "contactBubbleHidden",
            barNameState: "barNameHidden"
   
        }
    }
    displayName = Layout.name

    async AboutMouseOverEndEvent(passedBoolean) {
        console.log("about mouseover");

        // const delay = ms => new Promise(res => setTimeout(res, ms));
        if (this.state.aboutContainerState !== "leftBar") {
            switch (passedBoolean) {
                case true:
                    this.state.aboutDetailsClassName = "aboutDetailsContainerActive";
                    this.setState({ aboutDetailsShown: passedBoolean });
                    break;
                case false:
                    this.state.aboutDetailsClassName = "aboutDetailsContainerInactive";
                    this.setState({ aboutDetailsShown: true });
                    break;
            }
        } else {
        }
    } 

    async ContactMouseOverEndEvent(passedBoolean) {
        console.log("contact mouseover");

        // const delay = ms => new Promise(res => setTimeout(res, ms));
        if (this.state.contactContainerState !== "rightBar") {
            switch (passedBoolean) {
                case true:
                    this.state.contactDetailsClassName = "contactDetailsContainerActive";
                    this.setState({ contactDetailsShown: passedBoolean });
                    break;
                case false:
                    this.state.contactDetailsClassName = "contactDetailsContainerInactive";
                    this.setState({ contactDetailsShown: true });
                    break;
            }
        } else {

        }
    }

    worksMouseClickEvent = () => {
        console.log("WorksClickEvent");
        this.state.sidespacerContainerState = "sidespacerContainerAlt"
        this.state.aboutContainerState = "leftBar";
        this.state.aboutTextState = "leftBarText";
        this.state.contactContainerState = "rightBar";
        this.state.contactTextState = "rightBarText";
        this.state.aboutDetailsClassName = "aboutDetailsContainerHidden";
        this.state.isWorksActive = true;
        this.state.barNameState = "barNameActive";
        this.setState({ contactDetailsClassName: "contactDetailsContainerHidden"}); 
    }

    async sidebarMouseOverStartEvent(passedString) {
        console.log("sidebarMouseOverStartEvent");
        if (this.state.aboutContainerState !== "leftBarFull" && this.state.contactContainerState !== "rightBarFull") {
            switch (passedString) {
                case "about":
                    console.log("testtest")
                    this.setState({ aboutBubbleClass: "aboutBubble" })
                    break;
                case "contact":
                    console.log("testtest2")
                    this.setState({ contactBubbleClass: "contactBubble" })
                    break;
            }
        }
    }

    async sidebarMouseOverEndEvent(passedString) {
        console.log("sidebarMouseOverEndEvent")
        if (this.state.aboutContainerState !== "leftBarFull" && this.state.contactContainerState !== "rightBarFull") {
            switch (passedString) {
                case "about":
                    console.log("testtest")
                    this.setState({ aboutBubbleClass: "aboutBubbleHidden" })
                    break;
                case "contact":
                    console.log("testtest2")
                    this.setState({ contactBubbleClass: "contactBubbleHidden" })
                    break;
            }
        }
    }

    render() {

        return (
                <div id="greaterLayoutContainer">
                    <div id={this.state.aboutBubbleClass}>
                    Nicole Winkler is a maker, drafter and designer, born and raised in New York City. 
                    Her experience ranges from documenting existing buildings to gutting and redesigning homes to producing custom ceramic dining sets.   
                    Nicole completed her graduate degree, Master of Architeture, from Savannah College of Art and Design in 2017 and has been working independently in New York City since.

                            </div>
                    <div id={this.state.contactBubbleClass}>

                        Intrigued? <br />

                    Email me at <br />

                    nicole@nicolewinkler.co <br />
                    </div>
                <div id={this.state.aboutContainerState}
                    onMouseOver={() => this.sidebarMouseOverStartEvent("about")}
                    onMouseOut={() => this.sidebarMouseOverEndEvent("about")}

                >
                    <div class={this.state.sidespacerContainerState}>
                        <div id={this.state.aboutTextState}
                            onMouseOver={() => this.AboutMouseOverEndEvent(true)}
                            onMouseOut={() => this.AboutMouseOverEndEvent(false)}
                        >About <span id={this.state.barNameState}>: Nicole Winkler </span> </div>
                        <CSSTransition in={this.state.aboutDetailsShown} timeout={1000} classNames='fade'>
                            <div> {this.state.aboutDetailsShown == true && <AboutDetails passedClassName={this.state.aboutDetailsClassName} />} </div>
                            </CSSTransition>
                    </div>
                    </div>
                <div id="layoutcontainer">
                    <div id="layoutcontaineroverlay">
                    </div>
                    <div id="layouttopcontainer">
                        <Route render={({ location }) => (
                            <TransitionGroup>
                                <CSSTransition key={location.key} timeout={1000} classNames='fade'>
                                <Switch location={location}>
                                <Route exact path='/' component={Home} />
                                </Switch>
                                </CSSTransition>
                            </TransitionGroup>
                        )} />
                        <Route path='/'
                            render={props => (
                                <NavMenu {...props} isNavBarHidden={this.state.isNavBarHidden} worksMouseClickEvent={this.worksMouseClickEvent} />
                            )} />
                    </div>
                    <div id="sectionsContainer">
                        {this.state.isWorksActive && < Works />}
                </div>
                </div>
                <div id={this.state.contactContainerState}
                    onMouseOver={() => this.sidebarMouseOverStartEvent("contact")}
                    onMouseOut={() => this.sidebarMouseOverEndEvent("contact")}
                >
                    <div class={this.state.sidespacerContainerState}>
                        <div id={this.state.contactTextState}
                            onMouseOver={() => this.ContactMouseOverEndEvent(true)}
                            onMouseOut={() => this.ContactMouseOverEndEvent(false)}
                        >Contact  <span id={this.state.barNameState}> : Nicole Winkler </span></div>
                        <CSSTransition in={this.state.contactDetailsShown} timeout={1000} classNames='fade'>
                            <div> {this.state.contactDetailsShown == true && <ContactDetails passedClassName={this.state.contactDetailsClassName} />} </div>
                        </CSSTransition>
                    </div>
                </div>
            </div>
        );
    }
}