import React, { Component } from 'react';
import './Objects.css';
import { NavMenu } from './NavMenu';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import Gallery from 'react-grid-gallery';

/* LOCAL DATA IMPORT
import A2 from './images/A.2.jpg',;
import A1 from './images/A.1.jpg',;
import B1 from './images/B.1.jpg',;
import C1 from './images/C.1.jpg',;
import C2 from './images/C.2.jpg',;
import C3 from './images/C.3.jpg',;
import D1 from './images/D.1.jpg',;
import D2 from './images/D.2.jpg',;
import F1 from './images/F.1.jpg',;
import F2 from './images/F.2.jpg',;
import G1 from './images/G.1.jpg',;
import G2 from './images/G.2.jpg',;
*/

export class Objects extends Component {
    constructor(props) {
        super(props);
        this.objectsGalleryRef = React.createRef();
        this.state = {
            navTextStatus0: "objectsLocationText",
            navTextStatus1: "objectsLocationText",
            navTextStatus2: "objectsLocationText",
            navTextStatus3: "objectsLocationText",
        }
    }
    displayName = Objects.name

    updateNavStatus = (passedIndex) => {

        this.state.navTextStatus0 = "objectsLocationText" ;
        this.state.navTextStatus1 = "objectsLocationText";
        this.state.navTextStatus2 = "objectsLocationText";
        this.state.navTextStatus3 = "objectsLocationText";
   
        this.objectsGalleryRef.current.slideToIndex(passedIndex);
        switch (passedIndex) {
            case 0:
                this.setState({ navTextStatus0: "objectsLocationTextActive" })
                break;
            case 1:
                this.setState({ navTextStatus1: "objectsLocationTextActive" })
                break;
            case 2:
                this.setState({ navTextStatus2: "objectsLocationTextActive" })
                break;
            case 3:
                this.setState({ navTextStatus3: "objectsLocationTextActive" })
                break;
            case 4:
                this.setState({ navTextStatus4: "objectsLocationTextActive" })
                break;

        }
    }

    render() {

        const objectsImages = [
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Pottery+tiles.jpg',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Pottery+tiles.jpg',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/18+Handle+Vase.jpg',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/18+Handle+Vase.jpg',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/2-Tone+Mug.jpg',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/2-Tone+Mug.jpg',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Balls+Bowl.jpg',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Balls+Bowl.jpg',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Ballsy+Mug.jpg',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Ballsy+Mug.jpg',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Ballsy+Vase.jpg',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Ballsy+Vase.jpg',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Black+Abnormal+Mug.jpg',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Black+Abnormal+Mug.jpg',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Black+Bowl.jpg',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Black+Bowl.jpg',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Carly_s+Mug.jpg',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Carly_s+Mug.jpg',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Francesca_s+Mug.jpg',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Francesca_s+Mug.jpg',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Holy+Balls+Bowl.jpg',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Holy+Balls+Bowl.jpg',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Holy+Bowl+1.jpg',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Holy+Bowl+1.jpg',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Red%2BBlue+Dish.jpg',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Red%2BBlue+Dish.jpg',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Jewelry+Bowl+1.jpg',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Jewelry+Bowl+1.jpgg',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Jewelry+Bowl+2.jpg',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Jewelry+Bowl+2.jpg',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Russ_s+Balls+Bowl.jpg',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Russ_s+Balls+Bowl+.jpg',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Ryder+Balls+Bowl.jpg',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Ryder+Balls+Bowl.jpg',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Speckle+Bowl.jpg',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Speckle+Bowl.jpg',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Spikey+Pink+Mug.jpg',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Spikey+Pink+Mug.jpg',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Wavy+Balls+Mug.jpg',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/Wavy+Balls+Mug.jpg',
            },
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/White+%2B+Blue+Dish.jpg',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/White+%2B+Blue+Dish.jpg',
            }

            /* LOCAL DATA IMPORT
            {
                original: A2,
                thumbnail: A2,
                
            },
            {
                original: A1,
                thumbnail: A1,
                
            },

            {
                original: B1,
                thumbnail: B1,
                
            },

            {
                original: C1,
                thumbnail: C1,
                
            },

            {
                original: C2,
                thumbnail: C2,
                
            },

            {
                original: C3,
                thumbnail: C3,
                
            },

            {
                original: D1,
                thumbnail: D1,
                
            },

            {
                original: D2,
                thumbnail: D2,
                
            },

            {
                original: F1,
                thumbnail: F1,
                
            },

            {
                original: F2,
                thumbnail: F2,
                
            },

            {
                original: G1,
                thumbnail: G1,
                
            },

            {
                original: G2,
                thumbnail: G2,
                
            }*/
        ]

        /* const objectsCollage = [
            {
                original: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/objectscollage.png',
                thumbnail: 'https://d2qieg9daenfsb.cloudfront.net/hosted_assets/objects/objectscollage.png',
            }

        ]*/

        return (
            <div>
                <React.Fragment>
                    <div id="objectsContainer">
                        <h1 id="objectsText"> Objects </h1>
                        <div id="objectsLocationContainer">
                        </div>
                        <div id="objectsCarouselPaddingContainer">
                            <div id="objectsCarouselContainer">
                                <ImageGallery items={objectsImages} ref={this.objectsGalleryRef} showThumbnails={false} showPlayButton={false} showFullscreenButton={false} showNav={true} />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            </div>
        );
    }
}

