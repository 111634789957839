import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import './ContactDetails.css';

export class ContactDetails extends Component {
    displayName = ContactDetails.name
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <div className={this.props.passedClassName}>
                <div className="contactText"> Intrigued? <br/>

                Email me at <br/>
                
                nicole@nicolewinkler.co <br/>
                    </div>
            </div>
        );
    }
}
