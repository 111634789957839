import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import Home from './components/Home';
import Counter from './components/Counter';
import FetchData from './components/FetchData';

export default class App extends Component {
    displayName = App.name

    render() {
        return (
            <Layout />
        );
    }

}

